import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <header className="App-header">
        <div className="title">
          <a href="/">zoneStamp!</a>
        </div>
      </header>
    );
  }
}

export default Header;
